import React from "react"

import MenuIcon from "@mui/icons-material/Menu"
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { PrismicRichText } from "@prismicio/react"
import Link from "next/link"

import {
  COLORS,
  IoxioLogo,
  MAX_CONTAINER_WIDTH,
  StyledLink,
  StyledMobileLink,
} from "../styles"
import { getPathname, interactionOnClickHandlers } from "../utils"

const Navbar = ({ menu = [] }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  return (
    <NavBarContainer>
      <MuiToolbar disableGutters>
        <MobileBox alignItems="center">
          <Grid item xs={1}>
            <IconButton
              size="large"
              aria-label="Menu"
              aria-haspopup="true"
              {...interactionOnClickHandlers((event) =>
                setAnchorElNav(event.currentTarget)
              )}
              color="inherit"
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs={11}>
            <Link href="/">
              <a>{IoxioLogoInline}</a>
            </Link>
          </Grid>
          <MuiMenu
            disableScrollLock={true}
            id="navMenu"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={() => setAnchorElNav(null)}
          >
            {menu?.data?.menuLinks.map((menuItem, index) => (
              <MobileMuiMenuItem key={index} onClick={() => setAnchorElNav(null)}>
                <StyledMobileLink href={getPathname(menuItem.link.url)} passhref="true">
                  <PrismicRichText field={menuItem.label} />
                </StyledMobileLink>
              </MobileMuiMenuItem>
            ))}
          </MuiMenu>
        </MobileBox>
        <DesktopGrid container justifyContent="center" alignItems="center">
          <Grid item md={2}>
            <Link href="/">
              <a>{IoxioLogoInline}</a>
            </Link>
          </Grid>
          <Grid item lg={9}>
            <MenuSpacingBox>
              {menu?.data?.menuLinks.map((menuItem, index) => (
                <MuiButton key={index} onClick={() => setAnchorElNav(null)}>
                  <StyledLink href={getPathname(menuItem.link.url)} passhref="true">
                    {menuItem.label[0].text}
                  </StyledLink>
                </MuiButton>
              ))}
            </MenuSpacingBox>
          </Grid>
        </DesktopGrid>
      </MuiToolbar>
    </NavBarContainer>
  )
}

export default Navbar

const IoxioLogoInline = (
  <IoxioLogo viewBox="0 0 160 30">
    <title id="ioxioLogoID">IOXIO Logo</title>
    <desc id="ioxioLogoDescriptionID">Ioxio logo, navigates to front page</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4005 0.800781C24.5243 0.800781 18.0801 7.24498 18.0801 15.1212C18.0801 22.9974 24.5243 29.4416
                        32.4005 29.4416C40.2767 29.4416 46.7209 22.9974 46.7209 15.1212C46.7209 7.24498 40.2767 0.800781
                        32.4005 0.800781ZM32.4005 25.951C26.4038 25.951 21.5707 21.1179 21.5707 15.1212C21.5707 9.12453
                        26.4038 4.29139 32.4005 4.29139C38.3972 4.29139 43.2303 9.12453 43.2303 15.1212C43.2303 21.0284
                        38.3077 25.951 32.4005 25.951ZM3.4011 2.05368H0V28.636H3.4011V2.05368ZM112.953
                        2.05371H116.354V28.636H112.953V2.05371ZM145.442 0.800781C137.565 0.800781 131.121 7.24498 131.121
                        15.1212C131.121 22.9974 137.565 29.4416 145.442 29.4416C153.318 29.4416 159.762 22.9974 159.762
                        15.1212C159.762 7.24498 153.407 0.800781 145.442 0.800781ZM145.442 25.951C139.445 25.951 134.612
                        21.1179 134.612 15.1212C134.612 9.12453 139.445 4.29139 145.442 4.29139C151.438 4.29139 156.271 9.12453
                        156.271 15.1212C156.271 21.0284 151.438 25.951 145.442 25.951ZM54.1506 1.69629H60.4158L76.7948 12.8841L79.9274
                        15.0322L76.7948 17.1803L59.9683 28.6366H53.7031L73.7517 15.0322L54.1506 1.69629ZM80.7324 19.8652L93.6208
                        28.6365H99.7965L83.7755 17.8066L80.7324 19.8652ZM93.1733 1.69629H99.349L83.7755 12.2576L80.7324 10.199L93.1733
                        1.69629Z"
      fill="#EEEFEC"
    />
  </IoxioLogo>
)

const NavBarContainer = styled(AppBar)`
  position: absolute;
  top: 1.875rem;
  padding: 0 1rem;
  background: ${COLORS.NIGHT_SKY};
  opacity: 1;
  mix-blend-mode: normal;

  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 0;
  }
`

const MuiMenu = styled(Menu)`
  top: 4rem;
  display: flex;

  > .MuiPaper-root {
    width: calc(100% - 2rem);
    background-color: ${COLORS.NIGHT_SKY};
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    display: none;
  }
`
const MenuSpacingBox = styled(Box)`
  display: flex;
  align-content: flex-start;
  gap: 1rem;
  justify-content: flex-end;
`

const MuiButton = styled(Button)`
  align-content: space-between;
  color: ${COLORS.SAND};
  font-weight: 700;
  font-size: 1.1rem;
  white-space: nowrap;
  text-transform: none;
`

const MuiBox = styled(Box)`
  flex-grow: 1;
`

const MobileBox = styled(MuiBox)`
  display: flex;
  column-gap: 2rem;

  ${(props) => props.theme.breakpoints.up("lg")} {
    display: none;
  }
`

const DesktopGrid = styled(Grid)`
  display: none;

  ${(props) => props.theme.breakpoints.up("lg")} {
    display: flex;
    gap: 2rem;
    align-content: center;
    max-width: ${MAX_CONTAINER_WIDTH};
    height: 3rem;
    min-height: 3rem;
  }
`

const MuiToolbar = styled(Toolbar)`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0;
`

const MobileMuiMenuItem = styled(MenuItem)`
  width: 100%;
  padding: 1% 5%;
  color: ${COLORS.NIGHT_SKY};
  text-indent: 1rem;
  background-color: ${COLORS.NIGHT_SKY};
  border-radius: 10px;

  > a {
    background-color: ${COLORS.NIGHT_SKY};
  }

  > p {
    position: relative;
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    padding-left: 2rem;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      background-color: ${COLORS.NIGHT_SKY};
      border-radius: 10px;
    }
  }
`
